// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap';
require('@rails/ujs').start();
// require("turbolinks").start()
require('@rails/activestorage').start();
require('channels');
import intlTelInput from 'intl-tel-input';

$(document).ready(() => {
  const user_phonenumber_input = document.querySelector('.registration_form #user_phonenumber');
  try {
    if (user_phonenumber_input) {
      // change name attribute to phonenumber-raw
      user_phonenumber_input.setAttribute('name', 'user[phonenumber-raw]');
      intlTelInput(user_phonenumber_input, {
        nationalMode: false,
        separateDialCode: true,
        utilsScript: '/scripts/intl-tel-input-utils.js',
        hiddenInput: 'phonenumber',
        preferredCountries: ['de', 'gb', 'at', 'it'],
      });
    }
  } catch (err) {
    console.log('ERROR intlTelInput', err);
  }

  $('#register_link').on('click', registerUserRedirect);
  $('#sign_in_link').on('click', signInUserRedirect);

  $('#user_use_invitation_code').on('change', useInvitationCodeChange);
  useInvitationCodeChange();
  useSocialLoginPromptModal();
  initializeSortBy();

  window.togglePasswordVisibility = togglePasswordVisibility;

  addInvitationCodeToSocialLoginLinks();
});

function useSocialLoginPromptModal() {
  const modal = $('#socialLoginPromtModal');
  if (modal) {
    modal.modal('show');
  }
}

function useInvitationCodeChange() {
  const user_use_invitation_code = document.querySelector('#user_use_invitation_code');
  const user_invitation_code = document.querySelector('#user_invitation_code');
  if (user_use_invitation_code?.checked) {
    user_invitation_code.closest('div.form-group').classList.remove('d-none');
    user_use_invitation_code.closest('div.form-group').classList.add('d-none');
  }
}

function registerUserRedirect() {
  const user_login_input = document.querySelector('#user_login');
  const register_link = document.querySelector('#register_link');

  try {
    if (user_login_input && register_link) {
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.set('user[invitation_code]', document.querySelector('#user_invitation_code')?.value);

      if (user_login_input.value.includes('@')) {
        searchParams.set('user[email]', user_login_input.value);
      } else {
        searchParams.set('user[username]', user_login_input.value);
      }

      register_link.href += `?${searchParams.toString()}`;
    }
  } catch (err) {
    console.log('ERROR loginInput', err);
  }
}

function signInUserRedirect() {
  const sign_in_link = document.querySelector('#sign_in_link');

  if (sign_in_link) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('user[username]', document.querySelector('#user_username')?.value);
    searchParams.set('user[invitation_code]', document.querySelector('#user_invitation_code')?.value);
    sign_in_link.href += `?${searchParams.toString()}`;
  }
}

function togglePasswordVisibility(toggleButton, inputId) {
  const inputSelector = `#${inputId}`;
  const oldType = $(inputSelector).attr('type');
  const newType = oldType === 'password' ? 'text' : 'password';
  $(inputSelector).attr('type', newType);

  const newIcon = newType === 'password' ? '/icons/eye.svg' : '/icons/eye-slash.svg';
  $(toggleButton).children('img').attr('src', newIcon);
}

function addInvitationCodeToSocialLoginLinks() {
  const inputId = 'user_invitation_code';
  const linkIds = ['google_link', 'facebook_link', 'twitter_link', 'apple_link', 'unidy_link'];

  const updateLink = (link) => {
    const code = document.getElementById(inputId).value;
    const url = new URL(link.href);
    url.searchParams.set('invitation_code', code);
    link.href = url.toString();
  };

  const updateAllLinks = () =>
    linkIds
      .map((id) => document.getElementById(id))
      .filter(Boolean)
      .forEach(updateLink);

  // Add the invitation code from the input field to the oauth links, so we can carry it over to the registration
  updateAllLinks();
  document.getElementById('user_invitation_code')?.addEventListener('input', updateAllLinks);
}

function initializeSortBy() {
  document.getElementById('sort_by')?.addEventListener('change', (event) => event.target.closest('form')?.submit());
  document.getElementById('sort_order_toggle')?.addEventListener('click', (event) => {
    const form = event.target.closest('form');
    const sortOrderInput = document.getElementById('sort_order');
    if (!form || !sortOrderInput) return;
    sortOrderInput.value = sortOrderInput.value === 'asc' ? 'desc' : 'asc';
    form.submit();
  });
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
